<template>
  <div class="notifications-changed-images">
    <h4 class="notifications-changed-images__title">{{ title }}</h4>
    <div class="notifications-changed-images__list">
      <img
        v-for="(item, index) in list"
        :key="index"
        :src="`${item.image}?token=${accessToken}`"
        alt="changed photo"
        class="notifications-changed-images__image"
      />
    </div>
  </div>
</template>

<script>
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { mapState } from 'vuex'

const STATUS_ADDED = 'added'
const STATUS_DELETED = 'deleted'

export default {
  name: 'NotificationsChangedImages',
  props: {
    action: {
      type: String,
      required: true,
      validator: val => [STATUS_ADDED, STATUS_DELETED].includes(val)
    },
    list: { type: Array, required: true }
  },
  computed: {
    ...mapState(MODULE_SESSION, {
      accessToken: state => state.accessToken
    }),
    title() {
      const isDeletion = this.action === STATUS_DELETED
      return `БЫЛИ ${isDeletion ? 'УДАЛЕНЫ' : 'ДОБАВЛЕНЫ'} ФОТОГРАФИИ (${this.list.length})`
    }
  }
}
</script>
