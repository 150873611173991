export const HISTORY_TYPES = {
  COMMENT: 'comment',
  OUTGOING_CALL: 'outgoing_call',
  FILE: 'file',
  SHOW: 'show'
}
export const HISTORY_TYPES_MAP = [
  { label: 'исх. звонок', value: HISTORY_TYPES.OUTGOING_CALL },
  { label: 'комментарий', value: HISTORY_TYPES.COMMENT },
  { label: 'файл', value: HISTORY_TYPES.FILE },
  { label: 'показ', value: HISTORY_TYPES.SHOW }
]
