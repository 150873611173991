<template>
  <div class="notifications-changed-field">
    <h5 class="notifications-changed-field__label">{{ title }}</h5>
    <div class="notifications-changed-field__content">
      <span class="notifications-changed-field__title">БЫЛО</span>
      <span class="notifications-changed-field__value" v-html="getTextWithLinks(before)"></span>
      <span class="notifications-changed-field__line" />
      <span class="notifications-changed-field__title">СТАЛО</span>
      <span class="notifications-changed-field__value" v-html="getTextWithLinks(after)"></span>
    </div>
  </div>
</template>

<script>
import { formatDate, getTextWithLinks } from '@/utils/formatters'
import {
  HOUSE_TYPE_OPTIONS,
  AVITO_WALLS_TYPE_OPTIONS,
  FACING_TYPE_OPTIONS,
  PLOT_TYPE_OPTIONS,
  CIAN_PLACEMENTS_TYPE_OPTIONS,
  STATUS_OPTIONS,
  HIGHWAY_OPTIONS,
  DEAL_TYPE_OPTIONS,
  REALTY_PURPOSE_OPTIONS,
  MORTGAGE_OPTIONS,
  RENOVATION_TYPE_OPTIONS,
  PARKING_TYPE_OPTIONS,
  COMMUNAL_PAYMENTS_TYPE_OPTIONS,
  COUNTERS_TYPE_OPTIONS,
  RENT_DURATION_TYPE_OPTIONS,
  PREPAYMENT_TYPE_OPTIONS
} from '@/constants/objectRealtyOptions'
import { SHOWS_STATUSES_GROUP } from '@/constants/showsStatuses'
import { NOTIFICATION_FIELD_TITLES } from '@/constants/notificationFieldTitles'
import { HISTORY_TYPES_MAP } from '@/constants/historyEntryTypes'
import {
  OWNER_PROFILE,
  CUSTOMER_PROFILE,
  HISTORY_ENTRY_CUSTOMER,
  HISTORY_ENTRY_OWNER,
  ADVERT_SHOW
} from '@/constants/notificationObjectTypes'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MODULE_REALTY, SALE_SOURCES } from '@/store/modules/realty/realty.types'

const DEFAULT_VALUE = 'пустое значение'

const OPTIONS_MAP = {
  houseType: HOUSE_TYPE_OPTIONS,
  avitoWallsType: AVITO_WALLS_TYPE_OPTIONS,
  facingType: FACING_TYPE_OPTIONS,
  plotType: PLOT_TYPE_OPTIONS,
  publishTerms: CIAN_PLACEMENTS_TYPE_OPTIONS,
  status: STATUS_OPTIONS,
  highway: HIGHWAY_OPTIONS,
  dealType: DEAL_TYPE_OPTIONS,
  realtyPurpose: REALTY_PURPOSE_OPTIONS,
  parkingLot: PARKING_TYPE_OPTIONS,
  communalPaymentsIncluded: COMMUNAL_PAYMENTS_TYPE_OPTIONS,
  countersIncluded: COUNTERS_TYPE_OPTIONS,
  prepaymentMonths: PREPAYMENT_TYPE_OPTIONS,
  mortgage: MORTGAGE_OPTIONS,
  rentDuration: RENT_DURATION_TYPE_OPTIONS,
  renovationType: RENOVATION_TYPE_OPTIONS
}

export default {
  name: 'NotificationsChangedField',
  props: {
    type: { type: String, required: true },
    value: { type: Object, required: true },
    objectType: { type: String, required: true }
  },
  data() {
    return {
      before: null,
      after: null
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`,
      tenantOrganizationName: `${MODULE_SESSION}/tenantOrganizationName`,
      saleSources: `${MODULE_REALTY}/${SALE_SOURCES}`
    }),
    title() {
      return NOTIFICATION_FIELD_TITLES[this.objectType][this.type] || this.type
    }
  },
  watch: {
    value: {
      deep: true,
      handler: () => {
        this.formatValues()
      }
    }
  },
  created() {
    this.formatValues()
  },
  methods: {
    getTextWithLinks,
    async formatValues() {
      this.before = await this.formatValue(this.value.oldValue)
      this.after = await this.formatValue(this.value.newValue)
    },
    formatValue(value) {
      if ([OWNER_PROFILE, CUSTOMER_PROFILE].includes(this.objectType)) {
        if (this.type === 'callSource' || this.type === 'status') return value?.name || DEFAULT_VALUE
        if (this.type === 'adverts') {
          if (value.length) {
            const adverts = value.map(advert => advert.name)
            return adverts.join(', ')
          }
          return DEFAULT_VALUE
        }
      }
      if (this.objectType === ADVERT_SHOW && this.type === 'showStatus') {
        return SHOWS_STATUSES_GROUP[value].text
      }
      if ([HISTORY_ENTRY_CUSTOMER, HISTORY_ENTRY_OWNER].includes(this.objectType)) {
        if (this.type === 'reminderDatetime') {
          return formatDate(value.split(' ')[0], '.')
        }
        if (this.type === 'type') {
          return HISTORY_TYPES_MAP.find(type => type.value === value).label
        }
      }
      if (this.type === 'isPrimary') {
        return value ? 'Первичный' : 'Вторичный'
      }
      if (this.type === 'saleSource') {
        return this.saleSources.find(el => el.value === value).label
      }
      if (this.type === 'isPublished') {
        return value ? 'Опубликован' : 'Не опубликован'
      }
      if (this.type === 'isWater' || this.type === 'isElectricity') {
        return value ? 'Подключено' : 'Не подключено'
      }
      if (this.type === 'soldoutAt' || this.type === 'withdrawnAt') {
        return value ? formatDate(value, '.') : DEFAULT_VALUE
      }
      if (this.type === 'agent') {
        return value?.firstName || DEFAULT_VALUE
      }

      if (this.type === 'isGas' || this.type === 'isSewerage') {
        return value ? 'Подключена' : 'Не подключена'
      }

      if (
        [
          'isSpecial',
          'xmlCian',
          'xmlYandex',
          'xmlFacebook',
          'xmlAvito',
          'xmlYandexDirect',
          'xmlDomClick',
          'windowsOutdoor',
          'windowsCourtyard'
        ].includes(this.type)
      ) {
        return value ? 'Да' : 'Нет'
      }

      if (['communalPaymentsIncluded', 'countersIncluded', 'prepaymentMonths', 'mortgage'].includes(this.type)) {
        return OPTIONS_MAP[this.type].find(el => el.value === value)?.label || DEFAULT_VALUE
      }

      if (value) {
        if (OPTIONS_MAP[this.type]) {
          return OPTIONS_MAP[this.type].find(el => el.value === value).label
        }
        if (this.type === 'tags') return value.length ? value.map(({ name }) => name).join(', ') : DEFAULT_VALUE

        return value.name || value
      }

      return DEFAULT_VALUE
    }
  }
}
</script>
