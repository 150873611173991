<template>
  <div class="notifications">
    <div class="notifications__header">
      <v-page-title class="notifications__title">Уведомления</v-page-title>
      <div class="notifications__filters">
        <v-form-field label="ДАТА" class="notifications__filter" separate-label>
          <div class="notifications__filter-content">
            <v-input-date
              class="notifications__input-date"
              :value="queryParams.dateFrom"
              format="DD.MM.YY"
              placeholder="От"
              @input="changeDateFrom"
            />
            <span class="notifications__filter-line" />
            <v-input-date
              class="notifications__input-date"
              :value="queryParams.dateTo"
              format="DD.MM.YY"
              placeholder="До"
              @input="changeDateTo"
            />
          </div>
        </v-form-field>
        <v-form-field v-if="isRoleAdmin" label="АГЕНТ" class="notifications__filter">
          <v-select
            class="notifications__filter-select"
            :value="selectedAgent"
            :options="agentOptions"
            :reduce="agent => agent.id"
            :loading="agentOptionsLoading"
            :clearable="false"
            label="name"
            placeholder="Все"
            @input="changeAgent"
          />
        </v-form-field>
        <v-form-field label="ТИП УВЕДОМЛЕНИЯ" class="notifications__filter">
          <v-select
            class="notifications__filter-select"
            :value="selectedNotificationsType"
            :options="notificationTypesOptions"
            :reduce="notification => notification.id"
            label="name"
            placeholder="Все"
            :clearable="false"
            @input="changeNotificationType"
          />
        </v-form-field>
      </div>
    </div>

    <ul v-if="list.length" class="notifications__list">
      <notifications-list-item
        v-for="notification in list"
        :key="notification.id"
        v-bind="{ ...notification, object: notification.content.object, objectDraft: notification.content.objectDraft }"
        @click="showNotification(notification)"
      />
    </ul>
    <p v-else-if="!loading" class="notifications__list-item-empty">У вас нет уведомлений</p>

    <div class="notifications__pagination">
      <v-pagination :current="queryParams.page" :total="totalPages" @change="changePage" />
    </div>

    <!-- Dialog -->
    <notifications-info-dialog
      :visible.sync="notificationDialogVisible"
      :model="model"
      @accept="approveDraft($options.DRAFT_STATUS_ACCEPTED)"
      @reject="approveDraft($options.DRAFT_STATUS_REJECTED)"
      @fix="redirectToDraft(true)"
      @check="redirectToDraft"
    />
  </div>
</template>

<script>
import VPageTitle from '@/components/common/VPageTitle.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import VSelect from '@/components/common/VSelect.vue'
import NotificationsListItem from '@/components/Notifications/ListItem.vue'
import VPagination from '@/components/common/VPagination.vue'
import VFormField from '@/components/form/VFormField.vue'
import NotificationsInfoDialog from '@/components/Notifications/InfoDialog.vue'
import { MODULE_NOTIFICATIONS } from '@/store/modules/notifications/notifications.types'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { notificationsService, agentsService, advertsService, ownersService, customersService } from '@/services/http'
import { mapGetters, mapState } from 'vuex'
import { loadingService } from '@/services/loading'
import { DRAFT_STATUS_DRAFT, DRAFT_STATUS_ACCEPTED, DRAFT_STATUS_REJECTED } from '@/constants/draftStatuses'
import {
  NOTIFICATION_TYPES_OPTIONS_AGENT,
  NOTIFICATION_TYPES_OPTIONS_ADMIN
} from '@/constants/notificationTypesOptions'
import noticeService from '@/services/notification'
import {
  OWNER_PROFILE,
  CUSTOMER_PROFILE,
  HISTORY_ENTRY_OWNER,
  ADVERT,
  HISTORY_ENTRY_CUSTOMER,
  ADVERT_CALL,
  ADVERT_SHOW
} from '@/constants/notificationObjectTypes'
import { analyticsEvents } from '@/services/segment'
import { ANALYTICS_MAP } from '@/constants/notificationDescriptions'
import { getChangedCategoriesForAdverts } from '@/utils/common'

const ACTIONS_SERVICES_MAP = {
  [ADVERT]: advertsService,
  [OWNER_PROFILE]: ownersService,
  [HISTORY_ENTRY_OWNER]: ownersService,
  [CUSTOMER_PROFILE]: customersService,
  [HISTORY_ENTRY_CUSTOMER]: customersService,
  [ADVERT_CALL]: agentsService,
  [ADVERT_SHOW]: agentsService
}

export default {
  name: 'Notifications',
  components: {
    VPageTitle,
    VInputDate,
    VSelect,
    NotificationsListItem,
    VPagination,
    VFormField,
    NotificationsInfoDialog
  },
  DRAFT_STATUS_REJECTED,
  DRAFT_STATUS_ACCEPTED,
  data() {
    return {
      loading: false,
      list: [],
      params: {
        count: 0,
        limit: 15,
        page: 1
      },

      agentOptions: [],
      agentOptionsLoading: false,

      notificationDialogLoading: false,
      notificationDialogVisible: false,
      model: {}
    }
  },
  computed: {
    ...mapState({
      unreadCount: state => state[MODULE_NOTIFICATIONS].unreadCount
    }),
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    selectedAgent() {
      return this.agentOptions.find(agent => agent.id === this.queryParams.agent)
    },
    notificationTypesOptions() {
      return this.isRoleAdmin ? NOTIFICATION_TYPES_OPTIONS_ADMIN : NOTIFICATION_TYPES_OPTIONS_AGENT
    },
    selectedNotificationsType() {
      return this.notificationTypesOptions.find(
        notificationType => notificationType.id === this.queryParams.notificationType
      )
    },
    totalPages() {
      return Math.ceil(this.params.count / this.params.limit)
    },
    queryParams() {
      return {
        page: Number(this.$route.query.page) || 1,
        dateFrom: this.$route.query.dateFrom || undefined,
        dateTo: this.$route.query.dateTo || undefined,
        agent: Number(this.$route.query.agent) || undefined,
        notificationType: Number(this.$route.query.notificationType) || undefined
      }
    },

    objectId() {
      return this.model.content?.objectDraft?.id
    }
  },
  watch: {
    unreadCount(newValue, oldValue) {
      if (newValue > oldValue) this.fetchNotifications()
    },
    loading(val) {
      loadingService.setViewLoading(val)
    },
    notificationDialogLoading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchNotifications()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.fetchNotifications()
    })
    next()
  },
  created() {
    if (this.isRoleAdmin) this.fetchAgents()
  },
  methods: {
    updateQueryParams({ page, dateFrom, dateTo, agent, notificationType }) {
      const params = {
        page,
        dateFrom: dateFrom || undefined,
        dateTo: dateTo || undefined,
        agent: agent || undefined,
        notificationType: notificationType || undefined
      }

      this.$router.push({ query: params })
    },
    changePage(page) {
      this.updateQueryParams({ ...this.queryParams, page })
    },
    changeDateFrom(dateFrom) {
      this.updateQueryParams({ ...this.queryParams, dateFrom, page: 1 })
    },
    changeDateTo(dateTo) {
      this.updateQueryParams({ ...this.queryParams, dateTo, page: 1 })
    },
    changeAgent(agent) {
      this.updateQueryParams({ ...this.queryParams, agent, page: 1 })
    },
    changeNotificationType(notificationType) {
      this.updateQueryParams({ ...this.queryParams, notificationType, page: 1 })
    },
    fetchNotifications(params) {
      const fetchParams = { ...this.queryParams, ...params }
      const { limit } = this.params
      const offset = (fetchParams.page - 1) * limit
      this.loading = true
      notificationsService
        .getList({ limit, offset, ...this.queryParams, ...this.selectedNotificationsType?.value })
        .then(({ count, results }) => {
          this.list = results
          this.params = { ...this.params, count }
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchAgents() {
      this.agentOptionsLoading = true
      agentsService
        .select()
        .then(agents => {
          agents.unshift({
            name: 'Все',
            id: null
          })
          this.agentOptions = agents
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    showNotification({ id: notificationId, isRead, objectType, action }) {
      this.notificationDialogLoading = true
      notificationsService
        .getById(notificationId)
        .then(notificationDetail => {
          if (!isRead) {
            analyticsEvents.notificationRead({ notificationCategory: ANALYTICS_MAP[action]?.[objectType] })
          }
          this.model = notificationDetail
          this.list = this.list.map(item => {
            if (item.id === notificationId) return { ...item, isRead: true }
            return item
          })
          this.notificationDialogVisible = true
        })
        .finally(() => {
          this.notificationDialogLoading = false
        })
    },
    historyApproveDraft(draftStatus) {
      const service = ACTIONS_SERVICES_MAP[this.model.objectType]
      return service.approveHistoryDraft(this.objectId, this.model.content?.objectDraft?.client.id, draftStatus)
    },
    defaultApproveDraft(draftStatus) {
      const service = ACTIONS_SERVICES_MAP[this.model.objectType]
      return service[this.model.objectType === ADVERT_SHOW ? 'approveShow' : 'approveDraft'](this.objectId, {
        initialNotificationId: this.model.id,
        draftStatus
      })
    },
    approveDraft(draftStatus) {
      const {
        objectType,
        action,
        content: { changes }
      } = this.model
      this.notificationDialogLoading = true
      const approveMethod = [HISTORY_ENTRY_CUSTOMER, HISTORY_ENTRY_OWNER].includes(objectType)
        ? this.historyApproveDraft(draftStatus)
        : this.defaultApproveDraft(draftStatus)
      return approveMethod
        .then(() => {
          analyticsEvents.notificationReplied({
            notificationCategory: ANALYTICS_MAP[action]?.[objectType],
            notficationApproved: draftStatus === DRAFT_STATUS_ACCEPTED
          })
          if (draftStatus === DRAFT_STATUS_ACCEPTED && objectType === ADVERT) {
            analyticsEvents.propertyChanged(getChangedCategoriesForAdverts(changes))
          }
          this.notificationDialogVisible = false
          const messageSuccess = draftStatus === DRAFT_STATUS_ACCEPTED ? 'Изменения внесены!' : 'Изменения отклонены!'
          noticeService.success(messageSuccess)
        })
        .catch(() => {
          this.notificationDialogVisible = false
          const messageError =
            draftStatus === DRAFT_STATUS_ACCEPTED ? 'Не удалось принять изменения!' : 'Не удалось отклонить изменения!'
          noticeService.error(messageError)
        })
        .finally(() => {
          this.notificationDialogLoading = false
        })
    },
    redirectToDraft(isFix = false) {
      this.notificationDialogVisible = false
      if ([OWNER_PROFILE, HISTORY_ENTRY_OWNER].includes(this.model.objectType))
        this.$router.push({
          name: 'owner-edit',
          params: { ownerId: this.model.content?.object?.client?.id || this.model.content?.object?.id }
        })
      else if ([CUSTOMER_PROFILE, HISTORY_ENTRY_CUSTOMER].includes(this.model.objectType))
        this.$router.push({
          name: 'customer-edit',
          params: { customerId: this.model.content?.object?.client?.id || this.model.content?.object?.id }
        })
      else if (this.model.objectType === ADVERT)
        this.$router.push({
          name: 'objects-realty-edit',
          params: {
            objectId: this.objectId,
            status: DRAFT_STATUS_DRAFT,
            initialNotificationId: isFix ? undefined : this.model.id
          }
        })
      else if (this.model.objectType === ADVERT_CALL)
        this.$router.push({
          name: 'calls'
        })
      else if (this.model.objectType === ADVERT_SHOW)
        this.$router.push({
          name: 'shows'
        })
    },
    redirectToAdvert() {
      this.notificationDialogVisible = false
      this.$router.push({
        name: 'objects-realty-edit',
        params: { objectId: this.objectId, initialNotificationId: this.model.id }
      })
    }
  }
}
</script>
