<template>
  <div class="notifications-changed-files">
    <h4 class="notifications-changed-files__title">{{ title }}({{ list.length }})</h4>
    <div v-for="(file, idx) in list" :key="idx" class="notifications-changed-files__content">
      <span class="notifications-changed-files__field-title">ФАЙЛ</span>
      <span class="notifications-changed-files__value">{{ file.fileName || '-' }}</span>
      <div class="notifications-changed-files__actions">
        <v-button
          class="notifications-changed-files__action-button"
          :href="`${file.url}?token=${accessToken}`"
          target="_blank"
          >Посмотреть</v-button
        >
        <v-button class="notifications-changed-files__action-button" :href="`${file.url}?token=${accessToken}`" download
          >Скачать</v-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { mapState } from 'vuex'

const STATUS_ADDED = 'added'
const STATUS_DELETED = 'deleted'

export default {
  name: 'NotificationsChangedFiles',
  components: { VButton },
  props: {
    action: {
      type: String,
      required: true,
      validator: val => [STATUS_ADDED, STATUS_DELETED].includes(val)
    },
    list: { type: Array, required: true }
  },
  computed: {
    ...mapState(MODULE_SESSION, {
      accessToken: state => state.accessToken
    }),
    title() {
      const isDeletion = this.action === STATUS_DELETED
      return `${isDeletion ? 'УДАЛЕН' : 'ДОБАВЛЕН'} ФАЙЛ`
    }
  }
}
</script>
