export const DOCUMENTS_CATEGORY_EGRP = {
  name: 'egrp_extract',
  title: 'Выписка ЕГРН'
}
export const DOCUMENTS_CATEGORY_AGREEMENT = {
  name: 'agreement',
  title: 'Соглашение'
}
export const DOCUMENTS_CATEGORY_TITLE_DOCUMENT = {
  name: 'title_document',
  title: 'Правоустанавливающий документ'
}

export const DOCUMENTS_CATEGORIES = [
  DOCUMENTS_CATEGORY_EGRP,
  DOCUMENTS_CATEGORY_AGREEMENT,
  DOCUMENTS_CATEGORY_TITLE_DOCUMENT
]
export const DOCUMENTS_CATEGORIES_MAP = {
  [DOCUMENTS_CATEGORY_EGRP.name]: DOCUMENTS_CATEGORY_EGRP.title,
  [DOCUMENTS_CATEGORY_AGREEMENT.name]: DOCUMENTS_CATEGORY_AGREEMENT.title,
  [DOCUMENTS_CATEGORY_TITLE_DOCUMENT.name]: DOCUMENTS_CATEGORY_TITLE_DOCUMENT.title
}
