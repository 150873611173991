<template>
  <div class="notifications-changed-field-list">
    <h4 class="notifications-changed-field-list__title">ИЗМЕНЕНИЯ</h4>
    <notifications-changed-field
      v-for="([type, value], index) in list"
      :key="index"
      :object-type="objectType"
      :type="type"
      :value="value"
      class="notifications-changed-field-list__item"
    />
  </div>
</template>

<script>
import NotificationsChangedField from '@/components/Notifications/ChangedField.vue'
import { deepMerge } from '@/utils/common'
import { ADVERT_SHOW } from '@/constants/notificationObjectTypes'
import { formatPhone } from '@/utils/formatters'

export default {
  name: 'NotificationsChangedFieldList',
  components: { NotificationsChangedField },
  props: {
    changes: { type: Object, required: true },
    objectType: { type: String, required: true }
  },
  computed: {
    list() {
      if (this.changes) {
        const { ownerProfile, customerProfile, account, profile, ...fields } = this.changes

        if (this.objectType === ADVERT_SHOW) {
          const clientInfo = account ? { client: this.getShowClientChanges() } : {}
          return Object.entries(deepMerge(fields, clientInfo))
        }
        return Object.entries(
          deepMerge(fields, deepMerge(ownerProfile, deepMerge(customerProfile, deepMerge(account, profile))))
        )
      }

      return []
    }
  },
  methods: {
    getShowClientChanges() {
      const {
        account: { oldValue, newValue }
      } = this.changes
      return {
        oldValue: `${formatPhone(oldValue.phone)} - ${oldValue.firstName}`,
        newValue: `${formatPhone(newValue.phone)} - ${newValue.firstName}`
      }
    }
  }
}
</script>
