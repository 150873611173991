import {
  ADVERT,
  OWNER_PROFILE,
  HISTORY_ENTRY_OWNER,
  HISTORY_ENTRY_CUSTOMER,
  CUSTOMER_PROFILE,
  ADVERT_CALL,
  ADVERT_SHOW
} from '@/constants/notificationObjectTypes'
import { FIELD_TITLES } from '@/constants/objectRealtyOptions'

export const OWNER_FIELD_TITLES = {
  name: 'ИМЯ СОБСТВЕННИКА',
  firstName: 'ИМЯ СОБСТВЕННИКА',
  phone: 'ТЕЛЕФОН',
  email: 'EMAIL',
  agent: 'АГЕНТ',
  adverts: 'ОБЪЕКТ',
  status: 'СТАТУС',
  callSource: 'ИСТОЧНИК ЛИДА',
  updatedAt: 'ДАТА ОБНОВЛЕНИЯ'
}

export const CUSTOMER_FIELD_TITLES = {
  name: 'ИМЯ ПОКУПАТЕЛЯ',
  phone: 'ТЕЛЕФОН',
  email: 'EMAIL',
  agent: 'АГЕНТ',
  status: 'СТАТУС',
  callSource: 'ИСТОЧНИК ЛИДА',
  updatedAt: 'ДАТА ОБНОВЛЕНИЯ'
}

export const ADVERT_CALL_TITLES = {
  name: 'ИМЯ КЛИЕНТА',
  callSource: 'ИСТОЧНИК ЛИДА',
  callSourceName: 'ИСТОЧНИК ЛИДА',
  clientPhone: 'ТЕЛЕФОН КЛИЕНТА',
  isPrimary: 'СТАТУС ЛИДА',
  callDate: 'ДАТА ЛИДА',
  advert: 'ОБЪЕКТ НЕДВИЖИМОСТИ',
  text: 'КОММЕНТАРИЙ'
}

export const ADVERT_SHOW_TITLES = {
  advert: 'ОБЪЕКТ НЕДВИЖИМОСТИ',
  description: 'КОММЕНТАРИЙ',
  showDate: 'ДАТА ПОКАЗА',
  showTime: 'ВРЕМЯ ПОКАЗА',
  showStatus: 'СТАТУС',
  client: 'КЛИЕНТ'
}

export const HISTORY_ENTRY_TITLES = {
  reminderDatetime: 'ДАТА',
  text: 'КОММЕНТАРИЙ',
  type: 'ТИП'
}

export const NOTIFICATION_FIELD_TITLES = {
  [ADVERT]: FIELD_TITLES,
  [OWNER_PROFILE]: OWNER_FIELD_TITLES,
  [CUSTOMER_PROFILE]: CUSTOMER_FIELD_TITLES,
  [HISTORY_ENTRY_CUSTOMER]: HISTORY_ENTRY_TITLES,
  [HISTORY_ENTRY_OWNER]: HISTORY_ENTRY_TITLES,
  [ADVERT_CALL]: ADVERT_CALL_TITLES,
  [ADVERT_SHOW]: ADVERT_SHOW_TITLES
}
