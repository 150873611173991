import {
  NEW_OBJECT,
  EDIT_OBJECT,
  APPLY_EDIT_OBJECT,
  APPLY_NEW_OBJECT,
  REJECT_EDIT_OBJECT,
  REJECT_NEW_OBJECT,
  DELETE_OBJECT,
  REJECT_DELETE_OBJECT,
  APPLY_DELETE_OBJECT
} from '@/constants/notificationActions'
import {
  ADVERT,
  ADVERT_SHOW,
  ADVERT_CALL,
  OWNER_PROFILE,
  CUSTOMER_PROFILE,
  HISTORY_ENTRY_OWNER,
  HISTORY_ENTRY_CUSTOMER
} from '@/constants/notificationObjectTypes'

export const DESCRIPTIONS_MAP = {
  [NEW_OBJECT]: {
    [ADVERT]: `Запрос на публикацию объекта`,
    [ADVERT_SHOW]: `Добавлен показ`,
    [ADVERT_CALL]: `Изменения в лиде`, // при редактировании создаётся новый лид => тип будет NEW_OBJECT
    [OWNER_PROFILE]: `Добавлен собственник`,
    [CUSTOMER_PROFILE]: `Добавлен покупатель`,
    [HISTORY_ENTRY_OWNER]: `Обновления в контакте`,
    [HISTORY_ENTRY_CUSTOMER]: `Обновления в контакте`
  },
  [EDIT_OBJECT]: {
    [ADVERT]: `Изменения в объекте`,
    [ADVERT_SHOW]: `Изменения в показе`,
    [ADVERT_CALL]: `Изменения в лиде`,
    [OWNER_PROFILE]: `Изменения в контакте`,
    [CUSTOMER_PROFILE]: `Изменения в контакте`,
    [HISTORY_ENTRY_OWNER]: `Обновления в контакте`,
    [HISTORY_ENTRY_CUSTOMER]: `Обновления в контакте`
  },
  [APPLY_EDIT_OBJECT]: {
    [ADVERT]: `Изменения в объекте приняты`,
    [ADVERT_SHOW]: 'Изменения в показе приняты',
    [ADVERT_CALL]: `Изменения в лиде приняты`,
    [OWNER_PROFILE]: `Изменения в контакте приняты`,
    [CUSTOMER_PROFILE]: `Изменения в контакте приняты`,
    [HISTORY_ENTRY_OWNER]: `Обновления в контакте приняты`,
    [HISTORY_ENTRY_CUSTOMER]: `Обновления в контакте приняты`
  },
  [REJECT_EDIT_OBJECT]: {
    [ADVERT]: `Изменения в объекте не приняты`,
    [ADVERT_SHOW]: 'Изменения в показе не приняты',
    [ADVERT_CALL]: `Изменения в лиде не приняты`,
    [OWNER_PROFILE]: `Изменения в контакте не приняты`,
    [CUSTOMER_PROFILE]: `Изменения в контакте не приняты`,
    [HISTORY_ENTRY_OWNER]: `Обновления в контакте не приняты`,
    [HISTORY_ENTRY_CUSTOMER]: `Обновления в контакте не приняты`
  },
  [APPLY_NEW_OBJECT]: {
    [ADVERT]: `Новый объект опубликован`,
    [ADVERT_SHOW]: 'Новый показ опубликован',
    [ADVERT_CALL]: `Изменения в лиде приняты`, // при редактировании создаётся новый лид => тип будет NEW_OBJECT
    [OWNER_PROFILE]: `Новый собственник опубликован`,
    [CUSTOMER_PROFILE]: `Новый покупатель опубликован`,
    [HISTORY_ENTRY_OWNER]: `Новая запись опубликована`,
    [HISTORY_ENTRY_CUSTOMER]: `Новая запись опубликована`
  },
  [REJECT_NEW_OBJECT]: {
    [ADVERT]: `Новый объект не опубликован`,
    [ADVERT_SHOW]: 'Новый показ не опубликован',
    [ADVERT_CALL]: `Изменения в лиде не приняты`, // при редактировании создаётся новый лид => тип будет NEW_OBJECT
    [OWNER_PROFILE]: `Новый собственник не опубликован`,
    [CUSTOMER_PROFILE]: `Новый покупатель не опубликован`,
    [HISTORY_ENTRY_OWNER]: `Новая запись не опубликована`,
    [HISTORY_ENTRY_CUSTOMER]: `Новая запись не опубликована`
  },
  [APPLY_DELETE_OBJECT]: {
    [ADVERT_SHOW]: 'Запрос на удаление показа принят'
  },
  [REJECT_DELETE_OBJECT]: {
    [ADVERT_SHOW]: 'Запрос на удаление показа не принят'
  },
  [DELETE_OBJECT]: {
    [HISTORY_ENTRY_OWNER]: `Обновления в контакте`,
    [HISTORY_ENTRY_CUSTOMER]: `Обновления в контакте`,
    [ADVERT_SHOW]: `Запрос на удаление показа`
  }
}

export const ANALYTICS_MAP = {
  [NEW_OBJECT]: {
    [ADVERT]: `New Property`,
    [ADVERT_SHOW]: `Tours`,
    [ADVERT_CALL]: `Leads`,
    [OWNER_PROFILE]: `Property Owner Changed`,
    [CUSTOMER_PROFILE]: `Customer Changed`,
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`
  },
  [EDIT_OBJECT]: {
    [ADVERT]: `Property Changed`,
    [ADVERT_SHOW]: `Tours`,
    [ADVERT_CALL]: `Leads`,
    [OWNER_PROFILE]: `Property Owner Changed`,
    [CUSTOMER_PROFILE]: `Customer Changed`,
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`
  },
  [APPLY_EDIT_OBJECT]: {
    [ADVERT]: `Property Changed`,
    [ADVERT_SHOW]: `Tours`,
    [ADVERT_CALL]: `Leads`,
    [OWNER_PROFILE]: `Property Owner Changed`,
    [CUSTOMER_PROFILE]: `Customer Changed`,
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`
  },
  [REJECT_EDIT_OBJECT]: {
    [ADVERT]: `Property Changed`,
    [ADVERT_SHOW]: `Tours`,
    [ADVERT_CALL]: `Leads`,
    [OWNER_PROFILE]: `Property Owner Changed`,
    [CUSTOMER_PROFILE]: `Customer Changed`,
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`
  },
  [APPLY_NEW_OBJECT]: {
    [ADVERT]: `Property Changed`,
    [ADVERT_SHOW]: `Tours`,
    [ADVERT_CALL]: `Leads`,
    [OWNER_PROFILE]: `Property Owner Changed`,
    [CUSTOMER_PROFILE]: `Customer Changed`,
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`
  },
  [REJECT_NEW_OBJECT]: {
    [ADVERT]: `Property Changed`,
    [ADVERT_SHOW]: `Tours`,
    [ADVERT_CALL]: `Leads`,
    [OWNER_PROFILE]: `Property Owner Changed`,
    [CUSTOMER_PROFILE]: `Customer Changed`,
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`
  },
  [APPLY_DELETE_OBJECT]: {
    [ADVERT_SHOW]: `Tours`
  },
  [REJECT_DELETE_OBJECT]: {
    [ADVERT_SHOW]: `Tours`
  },
  [DELETE_OBJECT]: {
    [HISTORY_ENTRY_OWNER]: `Property Owner Updated`,
    [HISTORY_ENTRY_CUSTOMER]: `Customer Updated`,
    [ADVERT_SHOW]: `Tours`
  }
}
