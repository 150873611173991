import {
  ADVERT,
  ADVERT_SHOW,
  CUSTOMER_PROFILE,
  OWNER_PROFILE,
  HISTORY_ENTRY_OWNER,
  HISTORY_ENTRY_CUSTOMER,
  ADVERT_CALL
} from '@/constants/notificationObjectTypes'
import { NEW_OBJECT, EDIT_OBJECT, REJECT_APPLY_EDIT, REJECT_APPLY_NEW } from '@/constants/notificationActions'

export const NOTIFICATION_TYPES_OPTIONS_COMMON = [
  {
    id: 0,
    name: 'Все',
    value: null
  },
  {
    id: 1,
    name: 'Изменение покупателя',
    value: {
      objectType: CUSTOMER_PROFILE
    }
  },
  {
    id: 2,
    name: 'Изменение собственника',
    value: {
      objectType: OWNER_PROFILE
    }
  },
  {
    id: 3,
    name: 'Обновление покупателя',
    value: {
      objectType: HISTORY_ENTRY_CUSTOMER
    }
  },
  {
    id: 4,
    name: 'Обновление собственника',
    value: {
      objectType: HISTORY_ENTRY_OWNER
    }
  },
  {
    id: 5,
    name: 'Лиды',
    value: {
      objectType: ADVERT_CALL
    }
  },
  {
    id: 6,
    name: 'Показы',
    value: {
      objectType: ADVERT_SHOW
    }
  }
]
export const NOTIFICATION_TYPES_OPTIONS_ADMIN = [
  ...NOTIFICATION_TYPES_OPTIONS_COMMON,
  ...[
    {
      id: 7,
      name: 'Изменения в объекте',
      value: {
        objectType: ADVERT,
        action: EDIT_OBJECT
      }
    },
    {
      id: 8,
      name: 'Запрос на публикацию',
      value: {
        objectType: ADVERT,
        action: NEW_OBJECT
      }
    }
  ]
]
export const NOTIFICATION_TYPES_OPTIONS_AGENT = [
  ...NOTIFICATION_TYPES_OPTIONS_COMMON,
  ...[
    {
      id: 7,
      name: 'Изменения в объекте',
      value: {
        objectType: ADVERT,
        rejectOrApply: REJECT_APPLY_EDIT
      }
    },
    {
      id: 8,
      name: 'Запрос на публикацию',
      value: {
        objectType: ADVERT,
        rejectOrApply: REJECT_APPLY_NEW
      }
    }
  ]
]
